/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { gql } from "@apollo/client";

import CustomLink from "components/Links/CustomLink";
import Image from "components/Image/CustomImage";

import useTranslation from "next-translate/useTranslation";

import { makeStyles } from "tss-react/mui";
import Subject from "@mui/icons-material/Subject";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import SkeletonWithFade from "components/Loading/SkeletonWithFade";
import CardBody from "components/Card/CardBody";

import { hexToRgb } from "@mui/material";

import {
  pageTitle,
  whiteColor,
  zeroPadding,
  innerTitle,
  stravaBoxShadow,
  sectionTitleBlock,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  zeroPadding,
  pageTitle: {
    ...pageTitle,
    paddingLeft: 0,
    paddingRight: 0,
  },
  innerTitle,
  expandBox: {
    marginLeft: "-15px",
    marginRight: "-15px",
  },
  highlight: {
    ...stravaBoxShadow,
  },
  sectionTitleBlock,
  backgroundTopicImage: {
    borderRadius: theme.shape.borderRadius,
    filter: "brightness(50%)",
  },
  cardTitleWhite: {
    "&, & a": {
      marginTop: ".625rem",
      marginBottom: 0,
      minHeight: "auto",
      color: `${whiteColor} !important`,
    },
  },
  cardDescriptionWhite: {
    "&,& p": { color: `rgba(${hexToRgb(whiteColor)}, 1)` },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function BrowseByTopic(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { terms, topic, title, loading, pageHeader, preload } = props;

  const dummyTerms = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ];

  const termsToUse = (terms && terms.nodes) || dummyTerms;

  return (
    <>
      <GridContainer
        justifyContent="center"
        className={!pageHeader ? classes.sectionTitleBlock : ""}
      >
        <GridItem className={classes.zeroPadding}>
          {pageHeader ? (
            <h1 className={classes.pageTitle}>{title}</h1>
          ) : (
            <h2 className={classes.innerTitle}>{title}</h2>
          )}
        </GridItem>
        {!loading && terms?.topicContent?.pageDescription && (
          <GridItem className={classes.zeroPadding}>
            <SkeletonWithFade inProp={loading} width="60%">
              <p>{terms.topicContent.pageDescription}</p>
            </SkeletonWithFade>
          </GridItem>
        )}
      </GridContainer>
      <GridContainer justifyContent="center" className={classes.expandBox}>
        {termsToUse.map((term) => (
          <GridItem
            sm={6}
            md={4}
            key={term.id}
            className={classes.cardContentItem}
          >
            <SkeletonWithFade
              inProp={loading}
              height="270px"
              marginTop="30px"
              marginBottom="30px"
            >
              <Card
                background
                className={term && topic === term.slug ? classes.highlight : ""}
              >
                {term?.topicContent?.image?.sourceUrl && (
                  <Image
                    priority={preload}
                    fill
                    style={{ objectFit: "cover" }}
                    src={term.topicContent.image.sourceUrl}
                    alt={term.name}
                    className={classes.backgroundTopicImage}
                    sizes="(max-width: 400px) 100vw, (max-width: 600px) 50vw, 33vw"
                  />
                )}
                <CardBody background centered>
                  {term.uri && (
                    <CustomLink href={term.uri}>
                      <h3 className={classes.cardTitleWhite}>{term.name}</h3>
                    </CustomLink>
                  )}

                  <p className={classes.cardDescriptionWhite}>
                    {term.description}
                  </p>
                  {term && term.uri && topic !== term.slug && (
                    <CustomLink
                      type="button"
                      href={term.uri}
                      size="large"
                      color="secondary"
                    >
                      <Subject sx={{ mr: 1 }} />
                      {t("feeds:viewGuides")}
                    </CustomLink>
                  )}
                </CardBody>
              </Card>
            </SkeletonWithFade>
          </GridItem>
        ))}
      </GridContainer>
    </>
  );
}

export const TOPICSQUERYFRAGMENT = gql`
  fragment TopicsQueryFragment on RootQueryToTopicConnection {
    nodes {
      id
      uri
      slug
      name
      description
      topicContent {
        pageDescription
        image {
          id
          altText
          sourceUrl(size: MEDIUM)
        }
        imageLoadingColor
      }
    }
  }
`;
