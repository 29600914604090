import React from "react";
import { makeStyles } from "tss-react/mui";
import useTranslation from "next-translate/useTranslation";
import SkeletonWithFade from "components/Loading/SkeletonWithFade.js";
// @mui/icons-material
import RateReview from "@mui/icons-material/RateReview";
import Assessment from "@mui/icons-material/Assessment";
import MoneyOff from "@mui/icons-material/MoneyOff";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import { websiteName } from "settings/global-website-config.js";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles()({
  section: {
    padding: "20px 0",
    textAlign: "center",
  },
  title: {
    minHeight: "32px",
    textDecoration: "none",
  },
});

export default function HowItWorks(props) {
  const { howItWorks, loading } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  if (loading) return <div />;

  return (
    <div className={classes.section}>
      <Paper
        elevation={24}
        className={classes.howItWorksSection}
        sx={{ padding: 2 }}
      >
        <GridContainer>
          <GridItem>
            <h2 className={classes.title}>
              {t("home:howItWorks.title", { websiteName })}
            </h2>
          </GridItem>
          <GridItem sm={4} md={4}>
            <InfoArea
              title={howItWorks.box1.title}
              description={howItWorks.box1.content}
              icon={RateReview}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem sm={4} md={4}>
            <InfoArea
              title={howItWorks.box2.title}
              description={howItWorks.box2.content}
              icon={Assessment}
              iconColor="success"
              vertical
            />
          </GridItem>

          <GridItem sm={4}>
            <InfoArea
              title={howItWorks.box3.title}
              description={howItWorks.box3.content}
              icon={MoneyOff}
              iconColor="secondary"
              vertical
            />
          </GridItem>
        </GridContainer>
      </Paper>
    </div>
  );
}
