import React from "react";
import SyncIcon from "@mui/icons-material/Sync";

import { makeStyles } from "tss-react/mui";
import useTranslation from "next-translate/useTranslation";
import { Paper, Box } from "@mui/material";

import parse from "html-react-parser";
import { FeedBlock } from "components/Feedblocks/FeedBlock";
import { BrowseByTopic } from "components/Feedblocks/BrowseByTopic";
import HowItWorks from "pages-sections/home/HowItWorks";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import RecentItems from "components/Feedblocks/RecentItems";
import LandingPage from "pages-sections/dynamicPage/LandingPage";
import StravaLogin from "components/Login/StravaLogin";
import { BrowseByTaxonomy } from "components/Feedblocks/BrowseByTaxonomy";

import Hero from "components/Hero/Hero";

import { main, container } from "../../assets/jss/nextjs-material-kit-pro";
import CallToAction from "../../components/CallToAction/CallToAction";

const useStyles = makeStyles()((theme) => ({
  main: {
    ...main,
    paddingBottom: "40px",
    /* overflow: "hidden" */
  },
  syncGearContainer: { display: "flex", textAlign: "center" },
  container: {
    ...container,
    zIndex: 1,
  },
  website: {
    color: theme.palette.common.white,
    marginTop: "90px",
    textAlign: "center",
    "& h1": {
      fontSize: "3.5rem",
      fontWeight: 800,
      display: "inline-block",
      position: "relative",
      "@media (max-width: 768px)": {
        fontSize: "3rem",
      },
      [theme.breakpoints.down("xl")]: {
        fontSize: "3.5rem",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "2.5rem",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
      },
    },
    "& h2": {
      //  fontFamily: 'Roboto',
      color: theme.palette.common.white,
      fontSize: "2rem",

      display: "inline-block",
      position: "relative",
      [theme.breakpoints.down("lg")]: {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
    },
    "& h3": {
      //  fontFamily: 'Roboto',
      color: theme.palette.common.white,
      fontSize: "2rem",
      //  fontWeight: '600',
      display: "inline-block",
      position: "relative",
      [theme.breakpoints.down("lg")]: {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "1.2rem",
      },
    },
  },
}));

export default function Homebody(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const {
    pageTransition,
    guides,
    home,
    topics,
    categories,
    stores,
    lang,
    landingPageDetails,
  } = props;

  // choose the right sized hero image so we only download what we need:

  const combinedLoading = pageTransition;

  const siteStrapline = home && home.siteStrapline;

  const siteSubtitle = home && home.siteSubtitle;

  return (
    <>
      <Hero>
        <div className={classes.container}>
          <GridContainer className={classes.website}>
            <GridItem>
              <h1>{home && parse(siteStrapline)}</h1>
            </GridItem>
            <GridItem>{home && parse(siteSubtitle)}</GridItem>
            {/* <GridItem>
              <Box className={classes.syncGearContainer}>
                <Button endIcon={<SearchIcon />}>Search gear</Button>
                <StravaLogin location="collapsed" />
              </Box>
            </GridItem> */}
          </GridContainer>
        </div>
      </Hero>
      <div className={classes.main}>
        <div className={classes.container}>
          <>
            {!combinedLoading && (
              <Box sx={{ py: 2 }}>
                <CallToAction callToAction="parkrun" />
              </Box>
            )}

            {categories &&
              categories.nodes.map((category, index) => {
                const categoryName = category.name.toLowerCase();
                if (category.sports) {
                  return category.sports.edges.map((categorySportEdge, i) => (
                    <div
                      key={category.slug + (categorySportEdge.node.name || i)}
                    >
                      <FeedBlock
                        showDivider={!(index === 0 && i === 0)}
                        feedItems={
                          !pageTransition && categorySportEdge.products
                        }
                        sortImages
                        feedType="product"
                        feedTitle={t("feeds:trendingProducts", {
                          sport: categorySportEdge.node.name.toLowerCase(),
                          category: categoryName,
                        })}
                        fallbackImage={category.categoryDetails.fallbackImage}
                        moreButtonHref={categorySportEdge.uri}
                        moreButtonText={t("feeds:moreTrendingProducts", {
                          sport: categorySportEdge.node.name.toLowerCase(),
                          category: categoryName,
                        })}
                        noThumbnail
                        loading={combinedLoading}
                      />
                    </div>
                  ));
                }
              })}

            {!combinedLoading && (
              <CallToAction
                callToAction="syncGear"
                //description={description}
                href="/strava"
                icon={
                  <SyncIcon sx={{ fontSize: { sm: "3rem", md: "4rem" } }} />
                }
              />

              /*   <Paper elevation={24} sx={{ padding: 2 }}>
                <LandingPage pageData={{ landingPageDetails }} />
              </Paper> */
            )}
            <RecentItems loading={combinedLoading} showDivider />
            {!combinedLoading && (
              <Paper elevation={24} sx={{ padding: 2, mb: 4 }}>
                <BrowseByTaxonomy
                  key="stores-browseBy"
                  item={{ terms: stores }}
                  loading={combinedLoading}
                  lang={lang}
                  feedTitle={t("feeds:priceComparisonHeader")}
                  edgesOrNodes="nodes"
                  centerContent
                  grayscale
                />
              </Paper>
            )}
            {categories &&
              categories.nodes.map((category, index) => {
                if (category.sports) {
                  const sport =
                    category.sports.edges && category.sports.edges.length === 1
                      ? category.sports.edges[0].node.name.toLowerCase() + " "
                      : "";

                  return (
                    category.characteristics &&
                    category.characteristics.edges.map((item, i) => {
                      const taxonomies =
                        item.characteristic.node.label.toLowerCase();
                      const titleProps = {
                        taxonomies,
                        category: category.categoryDetails.singleName,
                        sport,
                      };

                      return (
                        <BrowseByTaxonomy
                          showDivider={!(index === 0 && i === 0)}
                          key={`${category.name}-${taxonomies}-browseBy`}
                          item={item}
                          loading={combinedLoading}
                          category={category.name}
                          feedTitle={t("feeds:recommendedProducts", titleProps)}
                          moreButtonText={t(
                            "feeds:seeAllBySportAndCategory",
                            titleProps
                          )}
                          edgesOrNodes="edges"
                          centerContent
                          sortByScore
                          alwaysShowMoreButton
                        />
                      );
                    })
                  );
                }
              })}
            <HowItWorks
              howItWorks={home?.howItWorks}
              loading={combinedLoading}
            />
          </>

          <BrowseByTopic
            terms={!pageTransition ? topics : null}
            title={t("feeds:allGuidesGeneric")}
            loading={combinedLoading}
            showMoreButton={false}
          />
          <FeedBlock
            feedItems={!pageTransition && guides}
            feedType="guide"
            feedTitle={t("feeds:latestGuides")}
            moreButtonText={t("feeds:moreGuides")}
            moreButtonHref="/guides"
            showShadow
            loading={combinedLoading}
          />
        </div>
      </div>
    </>
  );
}
