import React from "react";
import Link from "next/link";
import { makeStyles } from "tss-react/mui";
import ButtonBase from "@mui/material/ButtonBase";
import { useContainerDimensions } from "modules/useContainerDimensions";

import ImageModule from "../Image/ImageModule";
//import Image from 'components/Image/CustomImage';
import {
  blackColor,
  raisedImg,
  whiteColor,
  grayscale,
  focusHighlightShadow,
  titleFont,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  raisedImg,
  image: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    "& img": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageButton": {
        "& a": { opacity: "100%" },
      },
    },
  },
  highlightShadow: {
    "&:hover, &$focusVisible": {
      ...focusHighlightShadow,
    },
  },
  grayscale,
  focusVisible: {},
  imageButton: {
    ...titleFont,
    //fontWeight: 900,
    margin: "0px",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    padding: "5px",

    justifyContent: "center",
    "& a": {
      transition: "opacity 0.3s ease-in-out", //theme.transitions.create('opacity'),
      color: whiteColor,
      fontSize: "1.4em",
      opacity: "85%",
    },
    [theme.breakpoints.down("lg")]: {
      "& a": { fontSize: "1.3em" },
    },
    [theme.breakpoints.down("md")]: {
      "& a": { fontSize: "1.2em" },
    },
  },

  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: blackColor,
    transition: "opacity 0.3s ease-in-out", //theme.transitions.create('opacity'),
    borderRadius: theme.shape.borderRadius,
    maxWidth: "100%",
  },
  startDarker: { opacity: 0.4 },
  startLight: { opacity: 0 },
}));

export default function ImageButton(props) {
  const {
    featuredImage,
    item,
    uri,
    name,
    hideName,
    loading,
    startDarker,
    raised,
    highlightShadow,
    grayscale,
  } = props;
  const { classes, cx } = useStyles();

  const imageRef = React.useRef();
  const { width } = useContainerDimensions(imageRef, loading);

  const backdropClasses = cx({
    [classes.imageBackdrop]: true,
    [classes.startDarker]: startDarker,
    [classes.startDarker]: startDarker,
    [classes.startLight]: !startDarker,
  });

  const imageClasses = cx({
    [classes.image]: true,
    [classes.raisedImg]: raised,
    [classes.highlightShadow]: highlightShadow,
    [classes.grayscale]: grayscale,
  });

  return (
    <div ref={imageRef}>
      <Link href={uri} prefetch={false} passHref legacyBehavior>
        <ButtonBase
          focusRipple
          key={item.title}
          className={imageClasses}
          focusVisibleClassName={classes.focusVisible}
        >
          <ImageModule
            featuredImage={featuredImage}
            alt={featuredImage.altText || name}
            width={width}
            height={width}
            isThumbnail
          />
          <span className={backdropClasses} />
          <span className={classes.imageButton}>
            {uri && !hideName && <a>{name}</a>}
          </span>
        </ButtonBase>
      </Link>
    </div>
  );
}
