import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { hexToRgb } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  cardBodyCentered: {
    // padding: "0.9375rem 1.875rem",
    //flex: "1 1 auto",
    //WebkitBoxFlex: "1",
    marginTop: "15px",
    marginBottom: "10px", // reduced by 5px for sizesSelector
    display: "flex", // added
    flexWrap: "wrap", //added
    alignContent: "space-between", //added
    justifyContent: "center", //added
  },
  cardBody: {
    padding: "0.9375rem 1.875rem",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    marginTop: "15px",
    marginBottom: "10px", // reduced by 5px for sizesSelector
  },
  cardBodyBackground: {
    position: "relative",
    zIndex: "2",
    minHeight: "280px",
    paddingTop: "40px",
    paddingBottom: "40px",
    maxWidth: "440px",
    margin: "0 auto",
  },
  cardBodyPlain: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  cardBodyFormHorizontal: {
    paddingLeft: "15px",
    paddingRight: "15px",
    "& form": {
      margin: "0",
    },
  },

  cardSignup: {
    padding: "0px 30px 0px 30px",
  },
  cardBodyColor: {
    borderRadius: theme.shape.borderRadius,
    "& h1,& h2,& h3": {
      "& small": {
        color: "rgba(" + hexToRgb(theme.palette.common.white) + ", 0.8)",
      },
    },
  },
}));

export default function CardBody(props) {
  const {
    className,
    children,
    background,
    plain,
    formHorizontal,
    pricing,
    signup,
    color,
    centered,
    ...rest
  } = props;
  const { classes, cx } = useStyles();
  const cardBodyClasses = cx({
    [classes.cardBody]: !centered,
    [classes.cardBodyCentered]: centered,
    [classes.cardBodyBackground]: background,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyFormHorizontal]: formHorizontal,
    [classes.cardPricing]: pricing,
    [classes.cardSignup]: signup,
    [classes.cardBodyColor]: color,
    [className]: className !== undefined,
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  className: PropTypes.string,
  background: PropTypes.bool,
  plain: PropTypes.bool,
  formHorizontal: PropTypes.bool,
  pricing: PropTypes.bool,
  signup: PropTypes.bool,
  color: PropTypes.bool,
  children: PropTypes.node,
};
