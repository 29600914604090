import React from "react";
import Image from "components/Image/CustomImage";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import hero from "public/images/heros/hero-dark-67.png";

// core components

const useStyles = makeStyles()((theme) => ({
  outer: {
    backgroundColor: theme.palette.primary.main,
    height: "450px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      height: "375px",
    },
    [theme.breakpoints.down("md")]: {
      height: "300px",
    },
  },
  //position: 'absolute',

  hero: {
    "& #hero": {
      //  height: '100%',
      //  position: 'fixed !important',
      //   top: '-100px !important', // Possibly needed when there is more text on front page (should have a better solution anyway)
      objectFit: "cover",
      objectPosition: "center",
      height: "auto",
      width: "100vw",
      [theme.breakpoints.down("md")]: {
        top: "-100px !important",
      },
    },
  },
  content: {
    // backgroundColor: darkCustomColor,

    "& #hero": {
      //  position: 'absolute !important',
      //  zIndex: 2,
      // height: '500px',
    },
    paddingTop: "6rem",
    paddingBottom: "5rem",
    fontWeight: "bold",
    fontSize: "2rem",
    textAlign: "center",
    position: "relative",
    // zIndex: 1,
    [theme.breakpoints.down("xl")]: {
      paddingTop: "5.5rem",
    },
    [theme.breakpoints.down("lg")]: {
      // Should be 4.5rem when no stravaLogin
      paddingTop: "1.5rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "0.5rem",
    },
  },

  parallaxContainer: {
    display: "flex",
    position: "fixed !important",
    top: "-40px",
    height: "600px",
    [theme.breakpoints.down("xl")]: {
      top: "-80px",
    },
    /* [theme.breakpoints.down('md')]: {
      top: '-80px',
    }, */

    //  width: '102%',
    //  height: '102%',
    // top: '-1px',
    // left: '-1px',
    // maxHeight: '1600px',
    //   overflow: 'scroll',
    // position: 'fixed',
    // backgroundPosition: '50%',
    //  backgroundSize: 'cover', // was introducing a tiny strip to the right, testing without
    margin: "0",
    padding: "0",
    border: "0",
    // position: 'absolute',
    //   display: 'flex',
    //   alignItems: 'center'
  },
}));

export default function Hero(props) {
  const { className, children, ...rest } = props;
  const image = hero;

  const { classes, cx } = useStyles();

  let windowScrollTop = 0;
  const [transform, setTransform] = React.useState(
    "translate3d(0," + windowScrollTop + "px,0)"
  );
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  });
  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };

  const parallaxClasses = cx({
    [classes.parallaxContainer]: true,
    [className]: className !== undefined,
    [classes.hero]: true,
  });
  return (
    <>
      <div className={classes.outer}>
        <div
          id="parallax-container"
          className={parallaxClasses}
          style={{ transform }}
        >
          <Image
            id="hero"
            priority
            src={image}
            alt="Geerly hero image"
            sizes="100vw"
            placeholder="blur"
            {...rest}
          />
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </>
  );
}

Hero.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.oneOf([
    "primary",
    "rose",
    "dark",
    "info",
    "success",
    "warning",
    "danger",
  ]),
  children: PropTypes.node,
  style: PropTypes.object,
  image: PropTypes.string,
  small: PropTypes.bool,
};
