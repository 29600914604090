import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";

const useStyles = makeStyles()({
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "20px 0 0",
    //float: 'left' // chan
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px",
  },
  icon: {
    width: "2.25rem",
    height: "2.25rem",
    fontSize: "2.25rem",
  },
  descriptionWrapper: {
    //  color: grayColor[0],
    overflow: "hidden",
  },
  title: {
    margin: "1.75rem 0 0.875rem !important",
    minHeight: "unset",
  },

  iconWrapperVertical: {
    float: "none",
  },
  iconVertical: {
    width: "61px",
    height: "61px",
  },
});

export default function InfoArea(props) {
  const {
    title,
    description,
    iconColor,
    vertical,
    className,
    href,
    target,
    anchorText,
  } = props;
  const { classes, cx } = useStyles();
  const iconWrapper = cx({
    [classes.iconWrapper]: true,
    [classes.iconWrapperVertical]: vertical,
  });
  const iconClasses = cx({
    [classes.icon]: true,
    [classes.iconVertical]: vertical,
  });
  const infoAreaClasses = cx({
    [classes.infoArea]: true,
    [className]: className !== undefined,
  });
  let icon = null;
  switch (typeof props.icon) {
    case "string":
      icon = <Icon className={iconClasses}>{props.icon}</Icon>;
      break;
    default:
      icon = <props.icon className={iconClasses} />;
      break;
  }
  return (
    <Box
      className={infoAreaClasses}
      sx={{ "& svg": { color: `${iconColor}.main` } }}
    >
      <div className={iconWrapper}>{icon}</div>
      <div className={classes.descriptionWrapper}>
        <h3 className={classes.title}>{title}</h3>
        <div>
          <p>{description}</p>
          {href && (
            <a rel="noopener" href={href} target={target}>
              {anchorText}
            </a>
          )}
        </div>
      </div>
    </Box>
  );
}

InfoArea.defaultProps = {
  iconColor: "gray",
};

InfoArea.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  as: PropTypes.string,
  anchorText: PropTypes.string,
  description: PropTypes.node,
  target: PropTypes.oneOf(["_blank", "_new"]),
  iconColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "warning",
    "success",
    "info",
    //'gray',
    // 'primary',
    // 'secondary',
  ]),
  vertical: PropTypes.bool,
  className: PropTypes.string,
};
