import React from "react";

import CustomLink from "components/Links/CustomLink";

import useTranslation from "next-translate/useTranslation";
import SkeletonWithFade from "components/Loading/SkeletonWithFade";
import CustomParser from "modules/CustomParser";
import Divider from "@mui/material/Divider";
import ImageButton from "components/CustomButtons/ImageButton.js";
import { makeStyles } from "tss-react/mui";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import Box from "@mui/material/Box";
import Button from "components/CustomButtons/Button";
import MoreButton from "components/CustomButtons/MoreButton";
import { FeaturedImage } from "components/Image/FeaturedImage";
import {
  textButton,
  innerTitle,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()({
  innerTitle,
  textButton: {
    ...textButton,
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  textCenter: {
    textAlign: "center",
  },
});

export function BrowseByTaxonomy(props) {
  const { classes, cx } = useStyles();

  const { t } = useTranslation();

  const {
    item,
    loading,
    showTotals,
    feedTitle,
    category,
    moreButtonText,
    edgesOrNodes,
    showDivider,
    alwaysShowMoreButton,
    centerContent,
    makeTitleH1,
    grayscale,
    sortByScore,
  } = props;

  // NB DESCRIPTION not currently doing anything ?

  const { terms, characteristic, pageInfo, content } = item;

  const showMoreButton =
    alwaysShowMoreButton && pageInfo?.hasNextPage && characteristic?.uri;
  // let { title } = item; // NB: Can't see why this was being used. Have disabled as it was overwriting proper titles. Doesnt seem to have impacted anything but leaving here for now.

  // hide blocks for terms with no supporting products
  if (!loading && !terms) {
    return <div />;
  }

  const taxonomy =
    terms[edgesOrNodes] &&
    ((terms[edgesOrNodes][0].node && terms[edgesOrNodes][0].node.__typename) ||
      terms[edgesOrNodes][0].__typename);

  const title = !loading ? (
    feedTitle ||
    //   title ||
    t(`feeds:browseBy.${taxonomy?.toLowerCase()}`, { category })
  ) : (
    <SkeletonWithFade width="75%" inProp />
  );

  const titleStyles = cx({
    [classes.innerTitle]: true,
    [classes.textCenter]: centerContent,
  });

  const subtitleStyles = cx({
    [classes.textCenter]: centerContent,
  });

  const justifyProps = centerContent ? "center" : "flex-start";

  const imageButtons =
    taxonomy === "Sport" || taxonomy === "Brand" || taxonomy === "Category";

  const darkImage = taxonomy === "Sport" || taxonomy === "Category";
  // TODO: make this more sophisticated
  const containerSx = {};
  if (!showMoreButton || !characteristic) {
    containerSx.mb = "40px";
  }
  /* if (terms[edgesOrNodes] && sortByScore) {
    terms[edgesOrNodes].sort((a, b) => {
      console.log("TITLE", title, "a", a);
      return b.score - a.score;
    });
  } */
  return (
    <Box sx={containerSx}>
      {showDivider && !loading && <Divider variant="middle" />}
      <GridContainer justifyContent={justifyProps}>
        <GridItem md={10}>
          {(makeTitleH1 && <h1 className={titleStyles}>{title}</h1>) || (
            <h2 className={titleStyles}>{title}</h2>
          )}
          {taxonomy === "Store" && (
            <p className={subtitleStyles}>
              {t("feeds:priceComparisonStrapline")}
            </p>
          )}
        </GridItem>
        <GridItem>
          <div className={subtitleStyles}>
            <SkeletonWithFade width="40%" inProp={loading}>
              <CustomParser content={content} />
            </SkeletonWithFade>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContent={justifyProps}>
        {terms &&
          terms[edgesOrNodes]?.map((term, i) => {
            const featuredImage =
              term.node?.featuredImage?.node ||
              term.featuredImage?.node ||
              null;

            const termName =
              (taxonomy !== "brand" && term.name) || term.node?.name;

            return (
              <React.Fragment key={term.id || term.node?.id || i}>
                <GridItem
                  xxs={6}
                  xs={4}
                  sm={terms[edgesOrNodes].length <= 12 ? 3 : 2}
                  md={
                    terms[edgesOrNodes].length < 4
                      ? Math.round(8 / (terms[edgesOrNodes].length + 1))
                      : 2
                  }
                  lg={
                    terms[edgesOrNodes].length < 4
                      ? Math.round(8 / (terms[edgesOrNodes].length + 1))
                      : 2
                  }
                  className={classes.taxonomyItem}
                >
                  <Card blog plain center fullHeight>
                    {(loading || featuredImage) && (
                      <CardHeader
                        loading={loading}
                        image
                        noShadow
                        noSideMargins
                        button
                        grayscale={grayscale}
                      >
                        {imageButtons && featuredImage ? (
                          <ImageButton
                            featuredImage={featuredImage}
                            item={term}
                            uri={term.uri}
                            name={termName}
                            hideName={taxonomy === "Brand"}
                            loading={loading}
                            startDarker={darkImage}
                            raised={darkImage}
                            highlightShadow
                            grayscale={grayscale}
                          />
                        ) : (
                          <FeaturedImage
                            featuredImage={featuredImage}
                            fallbackAltText={`${
                              term.node?.name || term.title
                            } logo`}
                            href={term.uri}
                            loading={loading}
                            isThumbnail
                            showBadge={showTotals}
                            count={
                              term.products?.pageInfo.offsetPagination.total
                            }
                            ariaLabel={term.node?.name}
                          />
                        )}
                      </CardHeader>
                    )}

                    <div className={classes.taxonomyLabel}>
                      {(!imageButtons || !featuredImage) &&
                        (taxonomy !== "Brand" || !featuredImage) &&
                        taxonomy !== "Store" && (
                          <Button variant="link" className={classes.textButton}>
                            {term.uri && (
                              <CustomLink href={term.uri}>
                                {term.name || term.node?.name}
                              </CustomLink>
                            )}
                          </Button>
                        )}
                    </div>
                  </Card>
                </GridItem>
              </React.Fragment>
            );
          })}
      </GridContainer>
      {pageInfo?.hasNextPage && characteristic?.uri && (
        <MoreButton
          centerContent={centerContent}
          show={showMoreButton} // was pageInfo?.hasNextPage to only show where there was more to be seen
          uri={characteristic.uri}
          text={moreButtonText}
          loading={loading}
        />
      )}
    </Box>
  );
}
