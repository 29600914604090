import React from "react";

import useTranslation from "next-translate/useTranslation";

import { FeedBlock } from "components/Feedblocks/FeedBlock";

import { getRecentItems } from "modules/utils";

export default function RecentItems(props) {
  const { loading, showDivider, currentRecentItems, product } = props;
  const { t } = useTranslation();

  const [recentItems, setRecentItems] = React.useState(
    currentRecentItems || []
  );

  React.useEffect(() => {
    if (!currentRecentItems) {
      const recent = getRecentItems(true);
      if (recent) {
        setRecentItems(recent);
      }
    }
  }, [currentRecentItems]);

  const recentItemsToUse = !product
    ? recentItems
    : recentItems.filter((i) => i.id !== product.id);
  return (
    !loading &&
    recentItemsToUse.length > 0 && (
      <FeedBlock
        showDivider={showDivider}
        feedItems={{ nodes: recentItemsToUse }}
        feedType="product"
        feedTitle={t("feeds:recentlyViewed")}
        noThumbnail
        loading={loading}
      />
    )
  );
}
